import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {hasData} from "../utils";
import colors from "../constants/colors";
import arrow from "../images/chevron_down_grey.png";
import 'typeface-barlow';
import { Tooltip } from '../index'

const SELECT = styled.select`
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  outline: 0;
  border: 2px solid ${(props) => props.borderColor};
  color: ${(props) => props.fontColor};
  background: ${(props) => props.backgroundColor};
  position: relative;
  font-family: Barlow;
  
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  // text-indent: 1px;
  // text-overflow: '';
  padding-right: 35px;
  background-image: url(${arrow});
  background-size: 10px;
  background-position: calc(100% - 10px);
  background-repeat: no-repeat;
  opacity: .9;
  
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
  
  &[disabled] {
    opacity: .5;
  }
  
  /* CAUTION: IE hackery ahead */
  &::-ms-expand { 
      display: none; /* remove default arrow on ie10 and ie11 */
  }
  
  option {
    background: white;
    padding: 5px;
  }
`;

const LOADING = styled.span`
  position: absolute;
  bottom: 3px;
  left: 15px;
`;

function StyleSelect(props) {
  const [loading, setLoading] = useState(false);
  const [loadingSteps, setLoadingSteps] = useState(0);
  const [loadingString, setLoadingString] = useState(null);

  useEffect(() => {
    setLoading(props.loading)
  },[props.loading]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        if (loadingSteps === 15) {
          setLoadingSteps(0);
        } else {
          setLoadingSteps(loadingSteps + 1);
        }
      }, 200);
    }
  }, [loading, loadingSteps]);

  useEffect(() => {
    let i = 0;
    let s = '';
    while (i < loadingSteps) {
      i++;
      s = `${s}.`;
    }
    setLoadingString(<LOADING>{s}</LOADING>)
  }, [loadingSteps]);

  let fontColor = colors.brownGrey;
  let backgroundColor = colors.lightWhite;

  if (props.color === 'red') {
    fontColor = colors.white;
    backgroundColor = colors.salmon;
  }

  if (props.color === 'green') {
    fontColor = colors.white;
    backgroundColor = colors.tea;
  }

  if (props.color === 'blue') {
    fontColor = colors.white;
    backgroundColor = colors.softBlue;
  }

  if (props.color === 'orange') {
    fontColor = colors.white;
    backgroundColor = colors.orange;
  }

  if (props.color === 'purple') {
    fontColor = colors.white;
    backgroundColor = colors.lighterPurple;
  }

  if (props.color === 'black') {
    fontColor = colors.white;
    backgroundColor = colors.greyishBrown;
  }

  let borderColor = backgroundColor;

  if (hasData(props.hollow)) {
    borderColor = backgroundColor;
    fontColor = backgroundColor;
    backgroundColor = 'transparent';

    if (!hasData(props.color)) {
      fontColor = colors.brownGrey;
      borderColor = fontColor;
    }
  }

  const select = (
    <SELECT
      value={props.value}
      fontColor={fontColor}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      onChange={props.change !== undefined ? (e) => props.change(e) : null}
      onClick={props.click !== undefined ? () => props.click() : null}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
      style={props.style}
      data-tour={hasData(props.tour) ? props.tour : null}
      className={hasData(props.className) ? props.className : null}
    >
      {props.children}
      {loading ? loadingString : null}
    </SELECT>
  );

  // display a tooltip if configured
  if (hasData(props.toolTip)) {
    return (
      <Tooltip message={props.toolTip} position={hasData(props.toolTipPosition) ? props.toolTipPosition : 'top'} color={hasData(props.toolTipColor) ? props.toolTipColor : 'black'}>
        {select}
      </Tooltip>
    );
  } else {
    return select;
  }
}

StyleSelect.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  defaultValue: PropTypes.string,
  hollow: PropTypes.bool,
  loading: PropTypes.bool,
  click: PropTypes.func,
  change: PropTypes.func,
  disabled: PropTypes.bool,
  toolTip: PropTypes.string,
  toolTipColor: PropTypes.string,
  toolTipPosition: PropTypes.string,
};

export default StyleSelect;
