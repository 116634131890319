import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import background from '../images/CRW_1731.jpg';

const CONTAINER = styled.div`
  position: fixed;
  background-image: url(${background});
  background-size: cover;
  background-position: top center;
  transform: scaleX(-1);
  opacity: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

function StyleBody(props) {

  return (
    <CONTAINER
      {...props}
    >
      {props.children}
    </CONTAINER>
  );
}

StyleBody.propTypes = {
  color: PropTypes.string,
};

export default StyleBody;
