import React from "react";
import StyleH1 from "./Text/H1";
import StyleH2 from "./Text/H2";
import StyleH3 from "./Text/H3";
import StyleH4 from "./Text/H4";
import StyleH5 from "./Text/H5";
import StyleContainer from "./Layout/Container";
import StyleButton from "./Form/Button";
import StyleSelect from "./Form/Select";
import StyleTextArea from "./Form/TextArea";
import StyleInput from "./Form/Input";
import StyleP from "./Text/P";
import StyleUL from "./Text/UL";
import StyleOL from "./Text/OL";
import StyleModal from "./Layout/Modal";
import StyleProgress from "./Messages/Progress";
import StyleWizard from "./Wizard/Wizard";
import StyleStep from "./Wizard/Step";
import StyleMessage from "./Messages/Message";
import StyleTooltip from './Messages/Tooltip'
import StyleClippedText from './Text/ClippedText'
import StyleConfirmation from './Layout/Confirmation'
import translationsFile from './constants/translations';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import StyleImg from './Text/IMG'
import StyleBody from "./Layout/Body";

library.add(fab, far, fas, fal, fad);

export const H1 = (props) => {
  return <StyleH1 {...props} />
};

export const H2 = (props) => {
  return <StyleH2 {...props} />
};

export const H3 = (props) => {
  return <StyleH3 {...props} />
};

export const H4 = (props) => {
  return <StyleH4 {...props} />
};

export const H5 = (props) => {
  return <StyleH5 {...props} />
};

export const P = (props) => {
  return <StyleP {...props} />
};

export const UL = (props) => {
  return <StyleUL {...props} />
};

export const OL = (props) => {
  return <StyleOL {...props} />
};

export const Container = (props) => {
  return <StyleContainer {...props} />
};

export const Body = (props) => {
  return <StyleBody {...props} />
};

export const Button = (props) => {
  return <StyleButton {...props} />
};

export const Select = (props) => {
  return <StyleSelect {...props} />
};

export const Input = (props) => {
  return <StyleInput {...props} />
};

export const TextArea = (props) => {
  return <StyleTextArea {...props} />
};

export const Wizard = (props) => {
  return <StyleWizard {...props} />
};

export const Step = (props) => {
  return <StyleStep {...props} />
};

export const Message = (props) => {
  return <StyleMessage {...props} />
};

export const Modal = (props) => {
  return <StyleModal {...props} />
};

export const Confirmation = (props) => {
  return <StyleConfirmation {...props} />
};

export const Progress = (props) => {
  return <StyleProgress {...props} />
};

export const Tooltip = (props) => {
  return <StyleTooltip {...props} />
};

export const ClippedText = (props) => {
  return <StyleClippedText {...props} />
};

export const Img = (props) => {
  return <StyleImg {...props} />
};

export const translations = translationsFile;

