import React, { useEffect, useRef, useState } from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from 'styled-components';
import {FormattedMessage} from "react-intl";
import {Button} from "../index";
import PropTypes from "prop-types";
import StyleContainer from "./Container";
import {hasData} from "../utils";
import colors from '../constants/colors'

const CONTAINER = styled.div`
  position: fixed;
  text-align: initial;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 1000;
  
  display: grid;
  grid-template-columns: auto ${(props) => props.modalWidth}px auto;
  grid-template-rows: 100px max-content 0;
`;

const BACKGROUND = styled.div`
  grid-column: 1 / 4;
  grid-row: 1 / 4;
  background: black;
  opacity: .4;
  z-index: 999;
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const MODAL = styled.div`
  grid-column: 2;
  grid-row: 2;
  background: white;
  padding: 15px 25px 25px;
  z-index: 1001;
  border-radius: 4px;
  position: relative;
  margin-bottom: 100px;
`;

const HEADER = styled.div`
  font-size: 18px;
  min-height: 32px;
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
`;

const CLOSE = styled.span`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  opacity: .6;
  
  &:hover {
    opacity: 1;  
  }
`;

const BODY = styled.div``;

const FOOTER = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: auto;
  margin-top: 15px;
  
  &:button {
    width: auto;
  }
`;

const LEFT = styled.div`
`;

const RIGHT = styled.div`
  text-align: right;
  
  button {
    margin-right: 0;
    margin-left: 5px;
  }
`;

function StyleModal(props) {
  const modalRef = useRef();

  const [width, setWidth] = useState(null);

  useEffect(() => {
    calculateWidth();

    if (!hasData(props.disableEscape)) {
      window.addEventListener("keydown", escape);
      window.addEventListener("resize", calculateWidth);
      return () => {
        window.removeEventListener("keydown", escape);
        window.removeEventListener("resize", calculateWidth);
      }
    }
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const calculateWidth = () => {
    if (hasData(props.modalWidth)) {
      let w = props.modalWidth;
      if (window.innerWidth < (w + 100)) {
        w = window.innerWidth - 100;
      }
      setWidth(w);
    } else {
      setWidth(600);
    }
  };

  const escape = (e) => {
    if (e.key === 'Escape') {
      close();
    }
  }

  const close = () => {
    if (!hasData(props.disableClose)) {
      document.body.style.overflow = '';
      props.close()
    }
  };

  if (!hasData(width)) {
    return null;
  }

  return (
    <CONTAINER ref={modalRef} modalWidth={width} data-html2canvas-ignore>
      <BACKGROUND onClick={() => close() }/>
      <MODAL>

        <HEADER>
          {props.title}
          {!hasData(props.disableClose) ? <CLOSE>
            <FontAwesomeIcon onClick={() => close()} icon={['fas', 'times']}/>
          </CLOSE> : null}

        </HEADER>

        <BODY>
          {props.children}
        </BODY>

        <FOOTER>
          <LEFT>
            {!hasData(props.disableClose) ? <Button
              style={{float: "left", background: 'transparent', border: '2px solid transparent', paddingLeft: '0', color: colors.greyishBrown}}
              click={() => close()}
              color={'red'}
            >
              <FormattedMessage id="modal.cancel" defaultMessage="Annuleren"/>
            </Button> : null}
          </LEFT>

          <RIGHT>
            {props.buttons}
          </RIGHT>
        </FOOTER>

      </MODAL>
    </CONTAINER>
  );
}

StyleContainer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  tour: PropTypes.string,
  close: PropTypes.func.isRequired,
  modalWidth: PropTypes.string,
  scroll: PropTypes.string,
  title: PropTypes.object,
  buttons: PropTypes.object,
  disableEscape: PropTypes.bool,
  disableClose: PropTypes.bool,
};

export default StyleModal;
