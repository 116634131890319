import React from 'react';
import styled from 'styled-components';
import {hasData} from "../utils";
import PropTypes from "prop-types";
import {Tooltip} from "../index";
import 'typeface-barlow';

const CLIP = styled.div`
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.maxLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

function StyleClippedText(props) {

  return (
    <Tooltip color={hasData(props.color) ? props.color : 'black'} message={props.toolTip} position={props.position} delay={props.delay}>
      <CLIP
        width={hasData(props.width) ? props.width : '100%'}
        maxLines={hasData(props.maxLines) ? props.maxLines : 1}
        style={props.style}
        data-tour={hasData(props.tour) ? props.tour : null}
        className={hasData(props.className) ? props.className : null}
      >
        {props.children}
      </CLIP>
    </Tooltip>
  );
}

StyleClippedText.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.string,
  toolTip: PropTypes.string,
  color: PropTypes.string,
  maxLines: PropTypes.number,
  delay: PropTypes.number,
  loading: PropTypes.bool,
};

export default StyleClippedText;
