import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {hasData} from "../utils";
import colors from "../constants/colors";

const CONTAINER = styled.div`
  position: relative;
  padding: 20px;
  border-radius: 4px;
  background: ${(props) => props.backgroundColor};
  border: 2px solid ${(props) => props.borderColor};
  width: 60%;
  z-index: 1;
  margin-bottom: 25px;
`;

// needs to be made if needed
const LOADING = styled.span`
  position: absolute;
  bottom: 3px;
  left: 15px;
`;

function StyleContainer(props) {
  const [loading, setLoading] = useState(false);
  const [loadingSteps, setLoadingSteps] = useState(0);
  const [loadingString, setLoadingString] = useState(null);

  useEffect(() => {
    setLoading(props.loading)
  },[props.loading]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        if (loadingSteps === 15) {
          setLoadingSteps(0);
        } else {
          setLoadingSteps(loadingSteps + 1);
        }
      }, 200);
    }
  }, [loading, loadingSteps]);

  useEffect(() => {
    let i = 0;
    let s = '';
    while (i < loadingSteps) {
      i++;
      s = `${s}.`;
    }
    setLoadingString(<LOADING>{s}</LOADING>)
  }, [loadingSteps]);

  let backgroundColor = colors.white;

  if (props.color === 'red') {
    backgroundColor = colors.salmon;
  }

  if (props.color === 'green') {
    backgroundColor = colors.tea;
  }

  if (props.color === 'blue') {
    backgroundColor = colors.softBlue;
  }

  if (props.color === 'purple') {
    backgroundColor = colors.lighterPurple;
  }

  if (props.color === 'white') {
    backgroundColor = colors.white;
  }

  if (props.color === 'black') {
    backgroundColor = colors.black;
  }

  let borderColor = backgroundColor;

  if (hasData(props.hollow)) {
    borderColor = backgroundColor;
    backgroundColor = 'transparent';

    if (!hasData(props.color)) {
      borderColor = colors.brownGrey;
    }
  }

  return (
    <CONTAINER
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      onClick={props.click !== undefined ? () => props.click() : null}
      disabled={props.disabled}
      style={props.style}
      data-tour={hasData(props.tour) ? props.tour : null}
      className={hasData(props.className) ? props.className : null}
    >
      {props.children}
      {loading ? loadingString : null}
    </CONTAINER>
  );
}

StyleContainer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  tour: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
  click: PropTypes.func,
};

export default StyleContainer;
