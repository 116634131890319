import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {hasData} from "../utils";
import colors from "../constants/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import 'typeface-barlow';

const CLOSE = styled.span`
  position: absolute;
  top: 7px;
  right: 7px;
  line-height: 100%;
  cursor: pointer;
`;

const DIV = styled.div`
  position: relative;
  display: ${(props) => props.hidden ? 'hidden' : 'block'};
  border: 2px solid ${(props) => props.borderColor};
  color: ${(props) => props.fontColor};
  background: ${(props) => props.backgroundColor};
  width: calc(100% - 40px);
  padding: 0 10px;
  border-radius: 4px;
  font-size: 13px;
  line-height: 30px;
  z-index: 100;
  padding-right: 25px;
  margin-bottom: 5px;
  font-family: Barlow;
`;

function StyleMessage(props) {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    // setLoading(props.loading)
  });

  let fontColor = colors.brownGrey;
  let backgroundColor = colors.lightWhite;

  if (props.color === 'red') {
    fontColor = colors.white;
    backgroundColor = colors.salmon;
  }

  if (props.color === 'green') {
    fontColor = colors.white;
    backgroundColor = colors.tea;
  }

  if (props.color === 'blue') {
    fontColor = colors.white;
    backgroundColor = colors.softBlue;
  }

  if (props.color === 'purple') {
    fontColor = colors.white;
    backgroundColor = colors.lighterPurple;
  }

  if (props.color === 'black') {
    fontColor = colors.lightWhite;
    backgroundColor = colors.greyishBrown;
  }

  if (props.color === 'orange') {
    fontColor = colors.lightWhite;
    backgroundColor = colors.orange;
  }

  return (
    <DIV
      style={props.style}
      data-tour={hasData(props.tour) ? props.tour : null}
      className={hasData(props.className) ? props.className : null}
      hidden={hidden}
      fontColor={fontColor}
      backgroundColor={backgroundColor}
      borderColor={backgroundColor}
    >
      <CLOSE onClick={() => setHidden(true)}><FontAwesomeIcon icon={['fas', 'times']} size={'1x'} /></CLOSE>
      {props.children}
    </DIV>
  );
}

StyleMessage.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  message: PropTypes.any,
  tour: PropTypes.any,
};

export default StyleMessage;
