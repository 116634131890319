/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { hasData } from '../utils'
import colors from "../constants/colors";
import 'typeface-barlow';

const DIV = styled.span`
  position: relative;
  font-family: Barlow;
  user-select: none;
  ${(props) => props.highlight ? 'border-bottom: 1px dashed #ccc; cursor: pointer;' : ''}
`;

const ARROW = styled.span`
  position: absolute;
  display: inline-block;
  ${(props) => props.position};
  background: ${(props) => props.backgroundColor};
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
`;

const LABEL = styled.label`
  position: fixed;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  border: 2px solid ${(props) => props.borderColor};
  color: ${(props) => props.fontColor};
  background: ${(props) => props.backgroundColor};
  z-index: ${(props) => props.zIndex};
  width: max-content;
  max-width: 200px;
  max-height: initial;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  cursor: text;
  opacity: 0;
  transition: opacity 200ms linear;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  font-family: Barlow;
  
  &.fade {
    opacity: 1;
  }
`;

function StyleTooltip(props) {
  const [hidden, setHidden] = useState(true);
  const [toggle, setToggle] = useState(true);
  const [showTimer, setShowTimer] = useState(null);
  const [hideTimer, setHideTimer] = useState(null);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [arrow, setArrow] = useState('top: -5px;');
  const [delay, setDelay] = useState(500);
  const [clickDelay, setClickDelay] = useState(0);
  const [zIndex, setZIndex] = useState(-1);
  const [position, setPosition] = useState('bottom');

  useEffect(() => {
    let container = document;
    if (hasData(ref)) {
      let el = ref.current;
      while (el.parentNode) {
        el = el.parentNode;
        if ((hasData(el.style) && el.style.overflowY === 'scroll') || (el.clientHeight < el.scrollHeight && el.tagName === 'DIV') ) {
          container = el;
          break;
        }
      }
    }
    window.addEventListener("resize", scrolled);
    window.addEventListener("scroll", scrolled);
    container.addEventListener("scroll", scrolled);
    return () => {
      window.removeEventListener("resize", scrolled);
      window.removeEventListener("scroll", scrolled);
      container.removeEventListener("scroll", scrolled);
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    }
  }, []);

  useEffect(() => {
    setDelay(props.delay ? props.delay : delay);
    setPosition(props.position ? props.position : position);
  }, []);

  const scrolled = () => {
    //on scroll hide all tooltips
    setZIndex(-1);
  };

  useEffect(() => {
    if (hidden) {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
      const s = setTimeout(() => {
        setToggle(hidden);
      }, props.click ? clickDelay : delay);
      setTimeout(() => {
        setZIndex(-1);
      }, props.click ? clickDelay : delay * 2)
      setShowTimer(s);
    } else {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
      const h = setTimeout(() => {
        setToggle(hidden);
        setZIndex(9999);
      }, props.click ? clickDelay : delay);
      setHideTimer(h);
    }
  }, [hidden]);

  let fontColor = colors.brownGrey;
  let backgroundColor = colors.lightWhite;

  if (props.color === 'red') {
    fontColor = colors.white;
    backgroundColor = colors.salmon;
  }

  if (props.color === 'green') {
    fontColor = colors.white;
    backgroundColor = colors.tea;
  }

  if (props.color === 'blue') {
    fontColor = colors.white;
    backgroundColor = colors.softBlue;
  }

  if (props.color === 'blue') {
    fontColor = colors.white;
    backgroundColor = colors.softBlue;
  }

  if (props.color === 'purple') {
    fontColor = colors.white;
    backgroundColor = colors.lighterPurple;
  }

  if (props.color === 'black') {
    fontColor = colors.lightWhite;
    backgroundColor = colors.greyishBrown;
  }

  const ref = useRef(null);
  const labelRef = useRef(null);

  useEffect(() => {
    const coords = ref.current ? ref.current.getBoundingClientRect() : 0;
    const labelCoords = labelRef.current ? labelRef.current.getBoundingClientRect() : 0;
    let topOffset = 5 + coords.height;
    let leftOffset = 0;
    let a = arrow;

    if (hasData(position)) {
      if (position === 'top') {
        topOffset = -5 - labelCoords.height;
        a = 'bottom: -5px';
      }
      if (position === 'left') {
        topOffset = -5;
        leftOffset = -5 - labelCoords.width;
        a = 'right: -5px; top: 8px;';
      }
      if (position === 'right') {
        topOffset = -5;
        leftOffset = 5 + coords.width;
        a = 'left: -5px; top: 8px;';
      }
    }

    // tooltop falls off on the left
    if (position === 'left' && (coords.left + leftOffset) < 0) {
      setPosition('bottom');
      a = 'top: -5px;';
    }

    // tooltop falls off on the right
    if (position === 'right' && (coords.left + leftOffset + labelCoords.width) >= window.innerWidth) {
      setPosition('left');
      a = 'right: -5px; top: 10px;';
    }

    setArrow(a);
    setTop(parseInt(coords.top + topOffset));
    setLeft(parseInt(coords.left + leftOffset));

  }, [toggle, position]);

  return (
    <DIV
      ref={ref}
      style={props.style}
      data-tour={hasData(props.tour) ? props.tour : null}
      className={hasData(props.className) ? props.className : null}
      highlight={hasData(props.highlight) }
      click={hasData(props.click) }
      onMouseOver={!hasData(props.click) ? () => {setHidden(false)} : null}
      onMouseLeave={() => {
        if (hasData(props.click)) {
          setClickDelay(hidden ? 0 : delay);
        }
        setHidden(true)
      }}
      onFocus={() => {
        setHidden(false)
      }}
      onTouchEnd={() => {
        setHidden(true)
      }}
      onClick={hasData(props.click) ? () => {
        setHidden(!hidden);
      } : null}
    >
      <LABEL
        ref={labelRef}
        left={left}
        top={top}
        toggle={toggle}
        zIndex={zIndex}
        style={{opacity: zIndex === -1 ? 0 : 1}}
        fontColor={fontColor}
        backgroundColor={backgroundColor}
        borderColor={backgroundColor}
        className={toggle ? '' : 'fade' }
      >
        <ARROW backgroundColor={backgroundColor} position={arrow}/>
        {props.message}
      </LABEL>
      {props.children}
    </DIV>
  );
}

StyleTooltip.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  message: PropTypes.any,
  position: PropTypes.string,
  delay: PropTypes.number,
  click: PropTypes.bool,
  highlight: PropTypes.bool,
};

export default StyleTooltip;
