import md5 from 'md5';

/**
 *
 * @param item
 * @returns {*|boolean}
 */
export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}


/**
 * Does a deep merge of objects and arrays
 *
 * @param target
 * @param source
 * @returns {*}
 */
export function mergeDeep(target, source) {
  const output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = mergeDeep(target[key], source[key]);
        }
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

/**
 * @param data
 * @param newData
 * @param listKey
 *
 * Data is where to save
 * NewData is what to save
 * listKey is the key where you want to save your newData
 *
 * e.g. state.list, { your object }, objectId
 *
 */
export function addDataToState(data, newData, listKey) {
  const listIndex = listKey || null;

  if (data === undefined) {
    data = {};
  }
  if (listIndex !== null) {
    if (data[listIndex] === undefined) {
      data[listIndex] = {};
    }
    data[listIndex] = mergeDeep(data[listIndex], newData);
  } else {
    data = mergeDeep(data, newData);
  }

  return data;
}

/**
 * @param list
 * @param id
 *
 * @returns {*}
 */
export function getObjectById(list, id) {
  if (list[id] === undefined) {
    return null;
  }

  return list[id];
}

/**
 * check if the rights is available in the global state
 *
 * @param relations
 * @param from
 * @param to
 * @returns {null|{}}
 */
export function getRights(relations, from, to) {
  if (!hasData(relations)) {
    return null;
  }
  const key = md5(from + to);

  if (!hasData(relations[key])) {
    return null;
  }

  return getVar(relations, `${key}.data`);
}

/**
 * check if the rights is available in the global state
 *
 * @param relations
 * @param from
 * @param to
 * @param rights
 * @returns {null|{}}
 */
export function hasRight(relations, from, to, rights = ['OWNER', 'EDIT']) {
  if (relations === undefined) {
    return false;
  }
  const key = md5(from + to);
  if (relations[key] === undefined) {
    return false;
  }

  return rights.indexOf(relations[key].type) !== -1;
}

export function getVar(object, path) {
  if (!hasData(path)) {
    return null;
  }
  let o = object;
  if (o instanceof Object === false) {
    return null;
  }
  const v = path.split('.').map((e) => {
    if (o === null || (o[e] === undefined)) {
      if (o instanceof Array && o.indexOf(e) > 0) {
        o = o[o.indexOf(e)];
      } else {
        o = null;
      }
    } else {
      o = o[e];
    }
    return o;
  });
  return v[v.length - 1];
}

export function hasData(o) {
  if (o === undefined) {
    return false;
  }

  if (o === null) {
    return false;
  }

  if (o === false) {
    return false;
  }

  if (Array.isArray(o) && o.length === 0) {
    return false;
  }

  if(isObject(o) &&  Object.keys(o).length === 0) {
    return false;
  }

  return o.length !== 0;
}

export function hasStateChanged(_this, _var) {
  const change = _this.state[_var] !== _this.props[_var];
  if (change) {
    _this.setState({ [_var]: _this.props[_var] });
  }
  return change;
}

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export function invertColor(hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}


export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}
