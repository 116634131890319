import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {hasData} from "../utils";
import colors from "../constants/colors";
import 'typeface-barlow';
import { Tooltip } from '../index'

const INPUT = styled.input`
  appearance: none;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 5px;
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;
  outline: 0;
  border: 2px solid ${(props) => props.borderColor};
  color: ${(props) => props.fontColor};
  background: ${(props) => props.backgroundColor};
  position: relative;
  opacity: .9;
  font-family: Barlow;
  
  &:hover {
    opacity: 1;
    // cursor: pointer;
  }
  
  &[disabled] {
    opacity: .5;
  }
`;

function StyleInput(props) {

  let fontColor = colors.brownGrey;
  let backgroundColor = colors.lightWhite;

  if (props.color === 'red') {
    fontColor = colors.white;
    backgroundColor = colors.salmon;
  }

  if (props.color === 'green') {
    fontColor = colors.white;
    backgroundColor = colors.tea;
  }

  if (props.color === 'blue') {
    fontColor = colors.white;
    backgroundColor = colors.softBlue;
  }

  if (props.color === 'orange') {
    fontColor = colors.white;
    backgroundColor = colors.orange;
  }

  if (props.color === 'purple') {
    fontColor = colors.white;
    backgroundColor = colors.lighterPurple;
  }

  if (props.color === 'black') {
    fontColor = colors.white;
    backgroundColor = colors.greyishBrown;
  }

  let borderColor = backgroundColor;

  if (hasData(props.hollow)) {
    borderColor = backgroundColor;
    fontColor = backgroundColor;
    backgroundColor = 'transparent';

    if (!hasData(props.color)) {
      fontColor = colors.brownGrey;
      borderColor = fontColor;
    }
  }

  const input = (
      <INPUT
        fontColor={fontColor}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        onClick={props.onClick !== undefined ? (e) => props.onClick(e) : null}
        onChange={props.onChange !== undefined ? (e) => props.onChange(e) : null}
        onBlur={props.onBlur !== undefined ? (e) => props.onBlur(e) : null}
        disabled={props.disabled}
        style={props.style}
        data-tour={hasData(props.tour) ? props.tour : null}
        className={hasData(props.className) ? props.className : null}
        placeholder={hasData(props.placeholder) ? props.placeholder : null}
        type={hasData(props.type) ? props.type : 'text'}
        min={hasData(props.min) ? props.min : null}
        max={hasData(props.max) ? props.max : null}
        value={props.value}
        defaultValue={props.defaultValue}
      />
  );

  // display a tooltip if configured
  if (hasData(props.toolTip)) {
    return (
      <Tooltip message={props.toolTip} position={hasData(props.toolTipPosition) ? props.toolTipPosition : 'top'} color={hasData(props.toolTipColor) ? props.toolTipColor : 'black'}>
        {input}
      </Tooltip>
    );
  } else {
    return input;
  }
}

StyleInput.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  hollow: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  toolTip: PropTypes.string,
  toolTipColor: PropTypes.string,
  toolTipPosition: PropTypes.string,
};

export default StyleInput;
