import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import styled from 'styled-components';

const Confirmation = styled.div`
position: fixed;
left: 0;
top: 0;
width: 100%;
height: 100%;
z-index: 995;
background: rgba(0, 0, 0, 0.45);
`;

const CONFIRMATIONMODALINNER = styled.div`
background: #fff;
width: 700px;
height: auto;
box-sizing: border-box;
box-shadow: 0 1px 5px rgba(84, 84, 84, 0.7);
word-break: break-word;
padding: 20px 20px;
border-radius: 5px;
margin: 20% auto;
text-align: initial;
`;

const ICON = styled.div`
padding: 5px;
cursor: pointer;
color: #555555;
margin-right: 0px;
float: right;
`;

const CONFIRMMSG = styled.div`
text-align: center;
font-weight: 400;
color: #64AF82;
font-size: 24px;
margin-top: 0px;
margin-bottom: 15px;
`;

const CONFIRMMSGSML = styled.div`
text-align: center;
color: #64AF82;
font-size: 14px;
margin-bottom: 15px;
`;

const CONFIRMBTN = styled.span`
background: #64af82;
color: #fff;
padding: 10px 20px;
border-radius: 4px;
cursor: pointer;
width: fit-content;
outline: none;
border: 0px;
margin: 0px 15px;
`;

const CONFIRMBTN2 = styled.span`
background-color: rgb(240, 118, 112);
color: #fff;
padding: 10px 20px;
border-radius: 4px;
cursor: pointer;
width: fit-content;
outline: none;
border: 0px;
margin: 0px 15px;
`;


export function StyleConfirmation(props) {

    return (
        <Confirmation>
            <CONFIRMATIONMODALINNER>
                    <ICON>
                    <FontAwesomeIcon onClick={() => {
                        props.onModalClose()
                    }}  icon={['fas', 'times']} />
                    </ICON>

                    {props.checkIfStarted === true ?
                        <div>
                            <CONFIRMMSG>
                                <FormattedMessage
                                    id="widget.areYouSure"
                                    defaultMessage="Weet je zeker dat je deze taak wilt verwijderen?" />
                            </CONFIRMMSG>
                            <CONFIRMMSGSML>
                                <FormattedMessage
                                    id="widget.areYouSure2"
                                    defaultMessage="Er zijn leerlingen al gestart, wanneer je op ja klikt verdwijnen deze ook!" />
                            </CONFIRMMSGSML>
                        </div>
                        :
                        <CONFIRMMSG>
                            <FormattedMessage
                                id="widget.areYouSure"
                                defaultMessage="Weet je zeker dat je deze taak wilt verwijderen?" />
                        </CONFIRMMSG>}

                    <div style={{ textAlign: "center", padding: "20px 0px" }}>
                        <CONFIRMBTN onClick={() => {
                            props.onConfirmationClick()
                        }
                        }><FormattedMessage id="widget.yes" defaultMessage="Ja" /></CONFIRMBTN>

                        <CONFIRMBTN2 onClick={() => props.onModalClose()} >
                            <FormattedMessage id="widget.no" defaultMessage="Nee" />
                        </CONFIRMBTN2>
                    </div>

            </CONFIRMATIONMODALINNER>
        </Confirmation>
    );
}

export default StyleConfirmation;
