import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {hasData} from "../utils";
import colors from "../constants/colors";
import 'typeface-barlow';
import { Tooltip } from '../index'

const BUTTON = styled.button`
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  outline: 0;
  border: 2px solid ${(props) => props.borderColor};
  color: ${(props) => props.fontColor};
  background: ${(props) => props.backgroundColor};
  position: relative;
  opacity: 0.9;
  font-family: Barlow;
  
  &:hover, &:focus {
    opacity: 1;
    cursor: pointer;
  }
  
  &[disabled] {
    opacity: .5;
  }
`;

const LOADING = styled.span`
  position: absolute;
  bottom: 3px;
  left: 15px;
`;

const ICON = styled.span`
  margin-right: 10px;
`;

function StyleButton(props) {
  const [loading, setLoading] = useState(false);
  const [loadingSteps, setLoadingSteps] = useState(0);
  const [loadingString, setLoadingString] = useState(null);

  useEffect(() => {
    setLoading(props.loading)
  },[props.loading]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        if (loadingSteps === 15) {
          setLoadingSteps(0);
        } else {
          setLoadingSteps(loadingSteps + 1);
        }
      }, 200);
    }
  }, [loading, loadingSteps]);

  useEffect(() => {
    let i = 0;
    let s = '';
    while (i < loadingSteps) {
      i++;
      s = `${s}.`;
    }
    setLoadingString(<LOADING>{s}</LOADING>)
  }, [loadingSteps]);

  let fontColor = colors.brownGrey;
  let backgroundColor = colors.lightWhite;

  if (props.color === 'red') {
    fontColor = colors.white;
    backgroundColor = colors.salmon;
  }

  if (props.color === 'green') {
    fontColor = colors.white;
    backgroundColor = colors.tea;
  }

  if (props.color === 'grey') {
    fontColor = colors.white;
    backgroundColor = colors.grey;
  }

  if (props.color === 'blue') {
    fontColor = colors.white;
    backgroundColor = colors.softBlue;
  }

  if (props.color === 'purple') {
    fontColor = colors.white;
    backgroundColor = colors.lighterPurple;
  }

  if (props.color === 'orange') {
    fontColor = colors.white;
    backgroundColor = colors.orange;
  }

  if (props.color === 'black') {
    fontColor = colors.white;
    backgroundColor = colors.greyishBrown;
  }

  let borderColor = backgroundColor;

  if (hasData(props.hollow)) {
    borderColor = backgroundColor;
    fontColor = backgroundColor;
    backgroundColor = 'transparent';

    if (!hasData(props.color)) {
      fontColor = colors.brownGrey;
      borderColor = fontColor;
    }
  }

  const button = (
    <BUTTON
      fontColor={fontColor}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      onClick={props.click !== undefined ? (e) => props.click(e) : null}
      disabled={props.disabled}
      style={props.style}
      data-tour={hasData(props.tour) ? props.tour : null}
      className={hasData(props.className) ? props.className : null}
      ref={props.customRef}
    >
      {hasData(props.icon) ? <ICON>{props.icon}</ICON> : null}
      {props.children}
      {loading ? loadingString : null}
    </BUTTON>
  );

  // display a tooltip if configured
  if (hasData(props.toolTip)) {
    return (
      <Tooltip message={props.toolTip} position={hasData(props.toolTipPosition) ? props.toolTipPosition : 'top'} color={hasData(props.toolTipColor) ? props.toolTipColor : 'black'}>
        {button}
      </Tooltip>
    );
  } else {
    return button;
  }
}

StyleButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.object,
  color: PropTypes.string,
  hollow: PropTypes.bool,
  loading: PropTypes.bool,
  click: PropTypes.func,
  disabled: PropTypes.bool,
  customRef: PropTypes.any,
  toolTip: PropTypes.string,
  toolTipColor: PropTypes.string,
  toolTipPosition: PropTypes.string,
};

export default StyleButton;
