import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import imageNotFound from '../images/image-not-found.png';
import PropTypes from "prop-types";
import { hasData } from '../utils'

const IMG = styled.img``;

function StyleImg(props) {
  const [title, setTitle] = useState(null);

  useEffect(() => {
    if (hasData(props.errorMessage)) {
      setTitle(props.errorMessage)
    }
  }, [props.errorMessage]);

  return (
    <IMG
      {...props}
      onError= {(ev) => {
          ev.target.src = hasData(props.imageNotFound) ? props.imageNotFound : imageNotFound;
          if (hasData(title)) {
            ev.target.title = title;
          }
        }
      }
    />
  );
}

StyleImg.propTypes = {
  errorMessage: PropTypes.string,
  imageNotFound: PropTypes.object,
};

export default StyleImg;
