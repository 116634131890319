import React from 'react'
import {Body, Container, H1, P} from "./components";

function App() {

  return <div style={{ padding: '70px 40px'}}>
      <Container color={'white'} style={{opacity: '.7'}}>
        <H1 color={'black'}>
          Jelle Leek
          <br />
          <small>
            Bussum (1947)
          </small>
        </H1>
        <P color={'black'} style={{ fontStyle: 'italic'}}>Glas, niet de schittering, noch de helderheid, maar juist de bijzondere mogelijkheden van ontglazing vormen een belangrijke inspiratiebron van mijn werk.</P>
      </Container>
      <Container color={'white'} style={{opacity: '.7'}}>
        <P color={'black'}>Jelle Leek begon zijn artistieke loopbaan als keramist in 1975. De vaak monumentale stukken van zijn hand uit deze tijd hebben allen die specifieke kenmerken, die ook in zijn latere werk blijven terugkomen: eenvoud en rust.</P>
        <P color={'black'}>Tijdens deze keramische periode ontwikkelt zich zijn fascinatie voor de materie glas. Om aan zijn innerlijke drang tot onderzoeken gehoor te geven begint hij een ontdekkingsreis naar de bijzondere eigenschappen, die dit materiaal in zich verborgen houdt. Acht jaar van intensief experimenteren volgen, pas in 2000 brengt hij zijn werk naar buiten en beëindigt definitief zijn keramische carrière.</P>
        <P color={'black'}>De glasobjecten van Jelle Leek onderscheiden zich door hun ingetogen, bijna serene uitstraling. De zilversatijnen glans, als maanlicht op een bevroren wateroppervlak, kenmerkt zijn schaal- en kelkvormen. In combinatie met brons en steen benadrukt hij de kwetsbaarheid én de transparantie van zijn glascréaties.</P>
      </Container>
      <Body />
    </div>;
  }


export default App;
