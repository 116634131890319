import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from "../constants/colors";
import {hasData, getVar} from "../utils";
import {Button} from "../index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CONTAINER = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const BACKGROUND = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(props) => hasData(props.background)? props.background : "rgba(0, 0, 0, 0.45)"}; 
`;

const WIZARD = styled.div`
  position: absolute;
  width: ${(props) => hasData(props.width)? props.width+"px" : "800px"};
  left: calc(50% - ${(props) => hasData(props.width)? (props.width / 2) +"px" : "400px"});
  top: 200px; 
  padding: 30px;
  border-radius: 4px;
  background: ${colors.white};
  text-align: left;
`;

const BUTTONBAR = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  margin-top: 15px;
`;

const RIGHT = styled.div`
  text-align: right;
`;

const LEFT = styled.div`
`;

const CLOSE = styled.span`
  float: right;

  button {
    background: none;
    border: none;
    padding: 0px;
  }
`;

const TITLE = styled.span`
  display: inline-block;
`;

const HEADERBAR = styled.div`
  font-size: 24px;
  height: 64px;
  border-bottom: 1px solid #eaeaea;
`;

/**
 * @return {null}
 */
function StyleWizard(props) {
  const [show, setShow] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    setActiveStep(props.start);
  }, [props.start]);

  useEffect(() => {
    setShow(props.show);
    if (props.reset) {
      setActiveStep(props.start);
    }
  }, [props.show, props.reset, props.start]);

  if (!show) {
    return null;
  }
// fontSize: "24px", paddingBottom: "20px", borderBottom: "1px solid #eaeaea"
  return (
    <CONTAINER>
      <BACKGROUND background={props.background} onClick={() => props.close()}/>
      <WIZARD width={props.width}>

        <HEADERBAR>
        {hasData(props.title) ? 
        <TITLE>
          {props.title }
        </TITLE>
          : null}

        <CLOSE>
          <Button click={() => props.close()}>
            <FontAwesomeIcon style={{fontSize: "30px", paddingTop: "10px"}} icon={['fal', 'times']} />
          </Button>
        </CLOSE>
        </HEADERBAR>

        {/* todo Display some checks and warnings when there are issues with the use of this component */}
        {hasData(props.children)? (props.children.length !== props.stop ? 'Wrong amount of steps detected': null) : ''}

        {/* display the active step */}
        {hasData(props.children)? (props.children.filter(child => child.props.step === activeStep)) : ''}

        {/* buttonbar */}
        <BUTTONBAR>
          <LEFT>
            {activeStep > props.start ? <Button color={'blue'} hollow click={() => setActiveStep(activeStep - 1)}>Vorige</Button> : null}
          </LEFT>
          <RIGHT>

            {activeStep < props.stop ? 
            <Button color={'green'} click={() => setActiveStep(activeStep + 1)} disabled={!getVar(props, `rules.${activeStep}`)}>
              Volgende
            </Button> : 
            <Button color={'green'} click={() => {props.callback(); props.close()}} disabled={!getVar(props, `rules.${activeStep}`)}>
              Opslaan
            </Button>}

          </RIGHT>
        </BUTTONBAR>
      </WIZARD>
    </CONTAINER>
  );
}

StyleWizard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  tour: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
  click: PropTypes.func,
  start: PropTypes.number,
  callback: PropTypes.func,
  close: PropTypes.func,
  title: PropTypes.object,
  reset: PropTypes.bool,
  rules: PropTypes.object.isRequired,
};

export default StyleWizard;
