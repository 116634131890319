import React from 'react';
import styled from 'styled-components';
import colors from "../constants/colors";
import {hasData} from "../utils";
import PropTypes from "prop-types";
import 'typeface-barlow';

const P = styled.p`
  font-family: Barlow;
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.fontColor};
  font-size: 18px;
  line-height: 24px;
`;

function StyleP(props) {

  let fontColor = colors.brownGrey;

  if (props.color === 'red') {
    fontColor = colors.salmon;
  }

  if (props.color === 'green') {
    fontColor = colors.tea;
  }

  if (props.color === 'blue') {
    fontColor = colors.softBlue;
  }

  if (props.color === 'purple') {
    fontColor = colors.lighterPurple;
  }

  if (props.color === 'orange') {
    fontColor = colors.orange;
  }

  if (props.color === 'black') {
    fontColor = colors.greyishBrown;
  }

  if (props.color === 'white') {
    fontColor = colors.white;
  }

  return (
    <P
      fontColor={fontColor}
      weight={hasData(props.weight) ? props.weight : 'normal'}
      onClick={props.onClick !== undefined ? () => props.onClick() : null}
      style={props.style}
      data-tour={hasData(props.tour) ? props.tour : null}
      className={hasData(props.className) ? props.className : null}
    >
      {props.children}
    </P>
  );
}

StyleP.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  weight: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default StyleP;
