import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const STEP = styled.div``;

function StyleStep(props) {
  return (
    <STEP>
      {props.children}
    </STEP>
  );
}

StyleStep.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  tour: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
  enable: PropTypes.bool,
  click: PropTypes.func,
};

export default StyleStep;
