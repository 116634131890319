const colors = {
  black: '#000000',
  redBrown: '#942813',
  pine: '#2e5130',
  lightNavy: '#196597',
  darkSkyBlue: '#22a9e8',
  tomato: '#e45732',
  greyGreen: '#6c9c6c',
  darkTan: '#b58c47',
  butterscotch: '#fdb741',
  greyishBrown: '#4a4a4a',
  lightGrey: '#ececec',
  grey: '#9b9b9b',
  grey2: '#7c7c7c',
  wheat: '#fcd58f',
  white: '#ffffff',
  whiteTwo: '#f9f9f9',
  whiteThree: '#d8d8d8',
  palePurple: '#c389ce',
  nastyGreen: '#62ae41',
  duskBlue: '#2f60a4',
  tomatoTwo: '#da1919',
  warmGrey: '#9b9b9b',
  coldGrey: '#f1f1f1',
  vibrantPurple: '#9e00c4',
  whiteFour: '#ececec',
  whiteFive: '#efefef',
  greyBlue: '#68859c',
  paleTeal: '#a2d0a2',
  taskTodoColor: '#ffffff',
  taskTodoBackground: '#9b9b9b',
  taskTodoBorder: '#7c7c7c',
  taskDoingColor: '#ffffff',
  taskDoingBackground: '#1a6497',
  taskDoingBorder: '#145077',
  taskDoneColor: '#ffffff',
  taskDoneBackground: '#6b9c6b',
  taskDoneBorder: '#557c55',
  taskFailedColor: '#ffffff',
  taskFailedBackground: '#e45633',
  taskFailedBorder: '#b64529',
  btnGreyBackground: '#f9f9f9',
  btnGreyBorder: '#c7c7c7',
  btnGreyHover: '#c7c7c7',
  btnBlueBackground: '#22a9e8',
  btnBlueBorder: '#337ab7',
  btnBlueHover: '#337ab7',
  btnBlue2Background: '#196597',
  btnBlue2Border: '#145077',
  btnBlue2Hover: '#145077',
  btnGreenBackground: '#62ae41',
  btnGreenBorder: '#4e8b34',
  btnGreenHover: '#65b941',
  btnLightGreenBackground: '#a2d0a2',
  btnLightGreenBorder: '#80a680',
  btnLightGreenHover: '#80a680',
  btnGreen2Background: '#6b9c6b',
  btnGreen2Border: '#557c55',
  btnGreen2Hover: '#557c55',
  btnRedBackground: '#e45633',
  btnRedBorder: '#b64529',
  btnRedHover: '#b64529',
  btnOrangeBackground: '#ff8a00',
  btnOrangeBorder: '#cc6e00',
  btnOrangeHover: '#cc6e00',
  btnYellowBackground: '#fdb740',
  btnYellowBorder: '#ca9235',
  btnYellowHover: '#ca9235',
  wizenozeGreen: '#5ba393',
  wizenozeDarkGreen: '#498276',
  wizenozeReadLevelActive1: '#ffd500',
  wizenozeReadLevelActive2: '#ff8a00',
  wizenozeReadLevelActive3: '#62ae41',
  wizenozeReadLevelActive4: '#196597',
  wizenozeReadLevelActive5: '#4a4a4a',
  wizenozeReadLevelInactive: '#e0e0e0',
  wizenozeGrey: '#cccccc',
  wizenozeTitleGrey: '#3f4652',
  sidebarDefaultFont: '#ffffff',
  sidebarDefaultBackground: '#7c7c7c',
  sidebarDefaultHover: '#9b9b9b',
  sidebarDefaultBorder: '#4a4a4a',
  sidebarBlueFont: '#184762',
  sidebarBlueBackground: '#22A9E8',
  sidebarBlueHover: '#1B87B9',
  sidebarBlueBorder: '#184762',
  sidebarGreenFont: '#2e5130',
  sidebarGreenBackground: '#A2D0A2',
  sidebarGreenHover: '#6C9C6C',
  sidebarGreenBorder: '#2e5130',
  sidebarOrangeFont: '#523303',
  sidebarOrangeBackground: '#FF8A00',
  sidebarOrangeHover: '#835205',
  sidebarOrangeBorder: '#523303',
  tea: '#64af82',
  eggShell: '#ffe8c3',
  salmon: '#f07670',
  softBlue: '#62bee9',
  veryLightPinkTwo: '#e0e0e0',
  brownGrey: '#9b9b9b',
  lighterPurple: '#8e69e6',
  lightWhite: '#f9f9f9',
  orange: '#dc824b',
};

export default colors;
