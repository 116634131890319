import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from "../constants/colors";

const DIV = styled.div`
  position: absolute;
  width: ${(props) => props.progress}%;
  max-width: ${(props) => props.max};
  background: ${(props) => props.background};
  height: 10px;
  display: inline-block;
  border-radius: 3px;
  margin: 5px;
`;

function StyleProgress(props) {

  let backgroundColor = colors.lightWhite;

  if (props.color === 'red') {
    backgroundColor = colors.salmon;
  }

  if (props.color === 'green') {
    backgroundColor = colors.tea;
  }

  if (props.color === 'blue') {
    backgroundColor = colors.softBlue;
  }

  if (props.color === 'purple') {
    backgroundColor = colors.lighterPurple;
  }

  if (props.color === 'black') {
    backgroundColor = colors.greyishBrown;
  }

  if (props.color === 'orange') {
    backgroundColor = colors.orange;
  }

  return (
    <DIV progress={props.progress} max={props.max} background={backgroundColor} />
  );
}

StyleProgress.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  message: PropTypes.any,
  tour: PropTypes.any,
};

export default StyleProgress;
